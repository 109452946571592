export const CURRENCY_TYPE = [
  {
    value: '1',
    label: 'Fiat'
  },
  {
    value: '2',
    label: 'Crypto'
  }
];
export const WALLET = '1';
export const BANK_TRANSFER = '2';

export const TRANSACTION_TYPE = [
  {
    value: WALLET,
    label: 'Wallet'
  },
  {
    value: BANK_TRANSFER,
    label: 'Bank Transfer'
  }
];

export const BUSINESS_TYPE = [
  { value: 'aBusnessType', label: 'A Busness Type' },
  { value: 'bBusnessType', label: 'B Busness Type' },
  { value: 'cBusnessType', label: 'C Busness Type' }
];
