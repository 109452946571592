import { PhoneValueType } from 'types/general';
import { REQUIRED_TEXT, VALID_EMAIL_TEXT } from '../../constants';
import { SelectValueType } from 'types/general';
import * as yup from 'yup';

export type TransactionRowType = {
  order: string;
  amount: string;
  approved_by: string;
  date: Date;
  status: string;
};
export type BeneHistoryPropsType = {
  setActiveRow: (active?: number) => void;
  setActive: (active?: number) => void;
  data: {
    id: number;
  };
};
type PaymentType = {
  currencyType: { label: string; value: string } | undefined;
  transactionType: { label: string; value: string } | undefined;
  bankName: { label: string; value: string } | undefined;
  branchAddress: string;
  accountNumber: string;
  accountType: { label: string; value: string } | undefined;
  cryptoWalletId: string;
};
export type FormType = {
  companyName: string;
  companyNumber: PhoneValueType;
  countryDestination: string;
  city: string;
  street: string;
  postcode: string;
  businessType: string;
  contactPersonName: string;
  contactPersonSurname: string;
  email: string;
  phoneNumber: PhoneValueType;
  contactPersonsPhoneNumber: PhoneValueType;
  payments: PaymentType[];
};
export type AddOrEditBeneficiaryPropsType = {
  edit?: boolean;
};

export const BeneficiaryFormSchema = yup.object().shape({
  companyName: yup.string().required(REQUIRED_TEXT),
  companyNumber: yup
    .object()
    .shape({
      code: yup.string().required(REQUIRED_TEXT),
      number: yup.string().required(REQUIRED_TEXT)
    })
    .nullable()
    .required(REQUIRED_TEXT),
  countryDestination: yup.string().required(REQUIRED_TEXT),
  city: yup.string().required(REQUIRED_TEXT),
  street: yup.string().required(REQUIRED_TEXT),
  postcode: yup
    .string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .required(REQUIRED_TEXT),
  businessType: yup.object().nullable().required(REQUIRED_TEXT),
  contactPersonName: yup.string().required(REQUIRED_TEXT),
  contactPersonSurname: yup.string().required(REQUIRED_TEXT),
  email: yup.string().email(VALID_EMAIL_TEXT).required(REQUIRED_TEXT),
  phoneNumber: yup
    .object()
    .shape({
      code: yup.string().required(REQUIRED_TEXT),
      number: yup.string().required(REQUIRED_TEXT)
    })
    .nullable()
    .required(REQUIRED_TEXT),
  contactPersonsPhoneNumber: yup
    .object()
    .shape({
      code: yup.string().required(REQUIRED_TEXT),
      number: yup.string().required(REQUIRED_TEXT)
    })
    .nullable()
    .required(REQUIRED_TEXT),
  payments: yup.array().of(
    yup.object().shape({
      currencyType: yup.object().nullable().required(REQUIRED_TEXT),
      transactionType: yup.object().nullable().required(REQUIRED_TEXT),
      bankName: yup
        .object()
        .nullable()
        .when('transactionType', {
          is: (val: SelectValueType) => val?.value === '2',
          then: yup.object().required(REQUIRED_TEXT),
          otherwise: yup.object().notRequired()
        }),
      branchAddress: yup.string().when('transactionType', {
        is: (val: SelectValueType) => val?.value === '2',
        then: yup.string().required(REQUIRED_TEXT),
        otherwise: yup.string().notRequired()
      }),
      accountNumber: yup.string().when('transactionType', {
        is: (val: SelectValueType) => val?.value === '2',
        then: yup.string().required(REQUIRED_TEXT),
        otherwise: yup.string().notRequired()
      }),
      accountType: yup
        .object()
        .nullable()
        .when('transactionType', {
          is: (val: SelectValueType) => val?.value === '2',
          then: yup.object().required(REQUIRED_TEXT),
          otherwise: yup.object().notRequired()
        }),
      cryptoWalletId: yup.string().when('transactionType', {
        is: (val: SelectValueType) => val?.value === '1',
        then: yup.string().required(REQUIRED_TEXT),
        otherwise: yup.string().notRequired()
      })
    })
  )
});
