export const regCompany = {
  title: 'Time to register your company!',
  description: `The next step is completing your company and personal information. This will let you use the platform to the fullest!\nFill up the details and click “Move Forward”!`,
  step: 1
};
export const regOrganization = {
  title: 'Organisation Officers',
  step: 2
};
export const reqDocuments = {
  title: 'Required Documents',
  description: `All provided documents must be certified by a lawyer or a public notary.
  If any document is not in English then please send a copy together with the certified translation.`,
  step: 3
};
