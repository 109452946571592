export const idTypes = [
  { value: 'identity_card', label: 'ID' },
  { value: 'passport', label: 'Passport' }
];
export const companyDocList = [
  {
    title: 'Certificate of The Company Registration',
    key: 'compReg'
  },
  {
    title: 'Company Corporate Ownership Chart',
    key: 'corpOwn'
  },
  {
    title: 'Copies of Identity Proof',
    key: 'identityProof'
  },
  {
    title: 'Evidence of Residential Address',
    key: 'resAddress'
  },
  {
    title: 'Directors Appointment Resolution',
    key: 'appResolution'
  },
  {
    title: 'Memorandum & Articles of Association',
    key: 'memorandumAssociation'
  },
  {
    title: 'Notarized Power of Attorney',
    key: 'powerAttorney'
  }
];

export const userRoles = [
  {
    value: 'User',
    label: 'User'
  },
  {
    value: 'Authorizer',
    label: 'Authorizer'
  }
];
