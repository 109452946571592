import Title from 'components/Title';
import { useNavigate } from 'react-router-dom';
import BeneList from './BeneList';
import Filters from './Filters';

const Beneficiaries: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex justify-start items-center">
        <Title text="My Beneficiaries" color="green" />
        <span
          className="icon-plus ml-5 text-3xl cursor-pointer text-green"
          onClick={() => navigate('/beneficiaries/add')}
        ></span>
      </div>
      <Filters />
      <BeneList />
    </>
  );
};

export default Beneficiaries;
