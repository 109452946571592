import { yupResolver } from '@hookform/resolvers/yup';
import { Row, Col } from 'antd';
import * as yup from 'yup';
import Button from 'components/Button';
import Input from 'components/Input';
import { useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import Select from 'components/Select';

interface IFilter {
  search: string | undefined;
  favorites: string | undefined;
  order: string | undefined;
}

const FilterSchema = yup.object().shape({
  search: yup.string(),
  favorites: yup.string(),
  order: yup.string()
});

const Filters: React.FC = () => {
  const { control } = useForm<IFilter>({
    resolver: yupResolver(FilterSchema),
    mode: 'onTouched'
  });
  return (
    <div className="mt-5">
      <Row className="flex flex-wrap " gutter={16}>
        <Col flex="auto">
          <Controller
            control={control}
            name="search"
            render={({ field: { onChange } }) => {
              return (
                <Input
                  label="Search"
                  placeholder="Find By: Name / Email / Phone Number"
                  onChange={onChange}
                  wrapperClass="w-full"
                />
              );
            }}
          />
        </Col>
        <Col flex="115px" className="items-end flex">
          <Button
            type="default"
            color="light-green"
            size="md"
            className="h-9"
            // goTo={item.link}
          >
            Search
          </Button>
        </Col>
        <Col flex="115px">
          <Controller
            control={control}
            name="favorites"
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <Select
                  options={[
                    { label: 'Fav 1', value: 'Fav 1' },
                    { label: 'Fav 2', value: 'Fav 2' },
                    { label: 'Fav 3', value: 'Fav 3' }
                  ]}
                  wrapperClass="w-[110px]"
                  placeholder="Favorites"
                  label="Filters"
                  error={error?.message}
                  value={value}
                  onChange={(val) => {
                    console.log(val);
                  }}
                  isSearchable
                  greenBorder
                />
              );
            }}
          />
        </Col>
        <Col flex="115px">
          <Controller
            control={control}
            name="order"
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <Select
                  options={[
                    { label: 'opt 1', value: 'opt 1' },
                    { label: 'opt 2', value: 'opt 2' },
                    { label: 'opt 3', value: 'opt 3' }
                  ]}
                  label=""
                  wrapperClass="w-[110px] mt-[21px]"
                  placeholder="A-Z"
                  error={error?.message}
                  value={value}
                  onChange={(val) => {
                    console.log(val);
                  }}
                  isSearchable
                  greenBorder
                />
              );
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Filters;
