export const ADD_OFFICER = {
  ADD: {
    value: true,
    title: 'Add an Officer',
    buttonName: 'Add'
  },
  EDIT: {
    value: false,
    title: 'Edit an Officer',
    buttonName: 'Save'
  }
};
